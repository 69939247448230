import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from '../components/layout'; 
import indexStyles from '../styles/index.module.scss'
import imageBack from '../images/image_1.jpg';

const HomePage = () => {    
	return(
		<Layout>
			<img className={indexStyles.image} src={imageBack} alt="logo" />
      <div className={indexStyles.content}>
				<h1 className={indexStyles.frase}>Soluciones especializadas en información clave, para la toma de decisiones.</h1>
      </div>
    </Layout>
  ) 
}
export default HomePage;
